import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';

const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img, paragraphOne, paragraphTwo, paragraphThree } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        <Title title="About Me" />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="about-wrapper__image">
                <AboutImg alt="profile picture" filename={img} />
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text">
                  {paragraphOne ||
                    'I’m Danny, a self-taught software developer. Before COVID-19 I was working in hospitality and events running teams of 30+ people for large scale functions such as the UK Independent Film Awards, Saatchi Gallery and multiple high-end private openings.'}
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphTwo ||
                    'In March 2020 I decided to start learning how to code, which is something that I had wanted to do since before I was a teenager. I started with the basics of HTML, CSS, Javascript and then dived fully into React. I know how to utilise CSS frameworks such as Bootstrap and Materialize, my most common methods of styling are CSS Modules, Sass and Styled Components. In terms of React for state management my go to’s are useContext and Redux. '}
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphThree ||
                    "Since December 2020 I've been working professionally as a front-end developer for a small agency based in East London that builds Shopify Stores using Vanilla JS, Jquery, CSS Modules and bespoke websites using Gatsby.js, GraphQl, CSS Modules and Firebase. My experience here has given me a great foundation in pure Javascript and the taught me a lot in the fundamentels for good software development."}
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphThree ||
                    "Then in October 2021 I decided to be challenge myself, leave my comfort zone and work for a larger company with a much bigger codebase. I was fortunate enough to get a job working for NOW TV part of the Sky corperation as an associate developer, in which I've been exposed to an app, which utilises lots of in-house modules, Redux, Typescript, Jest, Mocha, Cypress and Dynatrace to name a few."}
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphThree || 'My other skills include'}
                </p>
                <ul style={{ fontSize: '1.4rem' }} className="about-wrapper__info-text">
                  <li>Git/ Git Hub</li>
                  <li>CRUD</li>
                  <li>REST API</li>
                  <li>Node.js</li>
                  <li>Typescript</li>
                  <li>CI/CD</li>
                  <li>Proficient ReactNative</li>
                  <li>Proficient SQL and NoSQL</li>
                </ul>
                <p className="about-wrapper__info-text">{paragraphThree || "What I'm learning"}</p>
                <ul style={{ fontSize: '1.4rem' }} className="about-wrapper__info-text">
                  <li>AWS and other cloud based platforms</li>
                  <li>Express.js</li>
                </ul>
                {/* {resume && (
                  <span className="d-flex mt-3">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cta-btn cta-btn--resume"
                      href={resume}
                    >
                      Resume
                    </a>
                  </span>
                )} */}
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
